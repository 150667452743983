import ContactSection from "../../components/contact-section";

const FindUs = () => {
    return (
        <>
            <br /><br />
            <ContactSection />
        </>
        
    );
}
 
export default FindUs;