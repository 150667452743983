import InnerPageContent from "../../components/inner-page-content";

const PageNotFound = () => {


        let firstPara = {
        imageUrl: "./images/coming-soon.jpg",
        heading: "404 | Oops The page you are looking for is not found",
        text: <>
            <br/><br/>           
            </>
     }
    return ( 
         <>
            <InnerPageContent {...firstPara} />
        </>
     );
}
 
export default PageNotFound;