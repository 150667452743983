import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './navigation.css';

const Navigation = () => {
    return (
            <Navbar collapseOnSelect expand="lg" className='navbar sticky-top custom-navbar navbar-dark overlay' data-spy="affix" data-offset-top="20">
            <Container className='container'>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="navbar-nav ml-auto">
                        <Nav.Link eventKey='0' as={Link} to='/' className='nav-link space-right'>Home</Nav.Link> 
                        <Nav.Link eventKey='1' as={Link} to='/about-us' className='nav-link space-right'>About us</Nav.Link>
                        <NavDropdown title="For Our Visitors" id="basic-nav-dropdown" className='space-right'>
                            <NavDropdown.Item eventKey='2' as={Link} to='/what-to-expect'>What to expect?</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Resources" id="basic-nav-dropdown" className='space-right'>
                            <NavDropdown.Item eventKey='4' as={Link} to='/recent-sermons'>Sermon recordings</NavDropdown.Item>
                            <NavDropdown.Item eventKey='5' as={Link} to='/free-online-bible-study'>Free online bible study</NavDropdown.Item>
                            {/* <NavDropdown.Item eventKey='6' as={Link} to='/free-online-bible-study'>Home bible study series</NavDropdown.Item> */}
                        </NavDropdown>
                        <Nav.Link eventKey='8' as={Link} to='/find-us' className='nav-link space-right'>Find us</Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>       
     );
}
 
export default Navigation;