import './home-header.css';
import { Link } from 'react-router-dom';


const HomePageHeader = () => {
    


    return ( 
        <header className="header">
            <div className="overlay">
                <h6 className="subtitle">A Warm Welcome Awaits You </h6>
                <p style={{"fontSize":"22px"}}>at the</p>
                <h1 className="title">Church of Christ</h1>
                <h3>"Where Truth and Love Meet"</h3> <br/>
                <div className="buttons text-center">
                    <Link to="/what-to-expect" className="btn btn-primary rounded w-lg btn-lg my-1"> New Here?</Link>
                    <Link to="/find-us" className="btn btn-outline-light rounded w-lg btn-lg my-1"> Find Us</Link>
                </div>  
                <div>
                    &nbsp;
                </div>
            </div>      
        </header>
     );
}
 
export default HomePageHeader;