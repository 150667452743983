import ReactAudioPlayer from 'react-audio-player';
import ReactPlayer from "react-player";

import './styles.css';

const ArchivedSermons = () => {

 /********************
  * update this list of sermons until hooked to backend
  */
    const listOfSermonRecordings = [
        { id: '44', recordingName: 'Sunday Sermon', recordingDate: '09/15/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/lRaoH1waqDI' },
        { id: '43', recordingName: 'Sunday Sermon', recordingDate: '09/08/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/Txm2YrvshQk' },
        { id: '42', recordingName: 'Sunday Sermon', recordingDate: '09/01/2024', preacherName: 'Aubrey', filePath: 'https://youtu.be/sgWd16D-V0w' },
        { id: '41', recordingName: 'Sunday Sermon - What Does the Bible Say?', recordingDate: '08/25/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/Naj0nsGY9Q4' },
        { id: '40', recordingName: 'Sunday Sermon - Lack of knowledge is Dangerous', recordingDate: '08/18/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/eJddt_aWsXE' },
        { id: '39', recordingName: 'Sunday Sermon - Resting Discouragement', recordingDate: '08/11/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/CLFSXh9KgoA' },
        { id: '38', recordingName: 'Sunday Sermon - Camouflaged Christians', recordingDate: '08/04/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/QSo7kUeD5Nc' },
        { id: '37', recordingName: 'Sunday Sermon - The Train Up a Child Problem', recordingDate: '07/28/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/ogS2hWoPVpA?si=302co9wyzUwYQSpb' },
        { id: '36', recordingName: 'Sunday Sermon', recordingDate: '07/14/2024', preacherName: 'Billy McVey', filePath: 'https://youtu.be/F3ScvN8NGiU' },
        { id: '35', recordingName: 'Sunday Sermon - "Open Wide"', recordingDate: '07/07/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/F3ScvN8NGiU' },
        { id: '34', recordingName: 'Sunday Sermon - "Jesus\'s Burden"', recordingDate: '06/23/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/aJtwHKQs_y0' },
        { id: '33', recordingName: 'Sunday Sermon - "What is Jesus Doing Right Now?"', recordingDate: '06/16/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/sLPijC0jVXE' },
        { id: '32', recordingName: 'Sunday Sermon - "Bible Authority Must Be Respected"', recordingDate: '06/09/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/Gh33Co2OvPQ' },
        { id: '31', recordingName: 'Sunday Sermon - "Only the Bible Has the Answers"', recordingDate: '06/02/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/ICC8dnIZEz8' },
        { id: '30', recordingName: 'Sunday Sermon - "The four musts for Marriage"', recordingDate: '05/19/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/XdL02kxDArA' },
        { id: '29', recordingName: 'Sunday Sermon - "Virtuous Women of the Bible"', recordingDate: '05/12/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/I_OTaIudF8Y' },
        { id: '28', recordingName: 'Sunday Sermon - "What Do We Do When We Don\'t Like or Cannot Answer Bible Questions?"', recordingDate: '05/05/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/j2ngE9OsNY4' },
        { id: '27', recordingName: 'Sunday Sermon - "The Church and the World"', recordingDate: '04/28/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/B3Ss8_ulpsE' },
        { id: '26', recordingName: 'Sunday Sermon - "Libel on the Bible"', recordingDate: '04/21/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/G3ax-c_-L_k' },
        { id: '24', recordingName: 'Sunday Sermon - "The ABC\'s of Salvation"', recordingDate: '04/07/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/2TONEi8JWtE' },
        { id: '23', recordingName: 'Sunday Sermon - "Paul\'s love and concern for Church in Philip"', recordingDate: '03/31/2024', preacherName: 'Rob Robinson', filePath: 'https://youtu.be/C4NexBq9woA' },
        { id: '22', recordingName: 'Sunday Sermon - "Reactions of God\'s Word"', recordingDate: '03/24/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/SqXtyZ4HIF0' },
        { id: '21', recordingName: 'Sunday Sermon - "The Essence of Worship"', recordingDate: '03/17/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/mYX8MTSQJE0' },
        { id: '20', recordingName: 'Sunday Sermon - "Go Fetch a Donkey"', recordingDate: '03/10/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/bpD1WGPDxwI' },
        { id: '19', recordingName: 'Sunday Sermon - "Thunder"', recordingDate: '03/03/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/tXbStSHz37c' },
        { id: '18', recordingName: 'Sunday Sermon - "Pointing Fingers"', recordingDate: '02/25/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/Ibwkby8f41o' },
        { id: '17', recordingName: 'Sunday Sermon - "Satan\'s Weaknesses"', recordingDate: '02/18/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/59lFshXTCH4?si=WvZ1ZAP1mrlWgCYP' },
        { id: '16', recordingName: 'Sunday Sermon - "I Just Don\'t Know How to Get Around the Truth"', recordingDate: '02/11/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/_VKmeCIlE5c?si=fB_6krE-uUbncukk' },
        { id: '15', recordingName: 'Sunday Sermon - "Pick a Side - You Can\'t be Neutral"', recordingDate: '02/04/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/zeIPPIb_kzE?si=h0jhT3ID8-mhU-9O' },
        { id: '14', recordingName: 'Sunday Sermon', recordingDate: '01/28/2024', preacherName: 'Billy McVey', filePath: 'https://youtu.be/H6CtW5JvoZo?si=d2I5RIf10tqQrY66' },
        { id: '13', recordingName: 'Sunday Sermon', recordingDate: '01/21/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/gjCOwy2jV4U?si=BRFs0us2zDhspMI-' },
        { id: '12', recordingName: 'Sunday Sermon', recordingDate: '01/14/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/Wz3khmOUPyw?si=a55f9kQP6oKLwGhm' },
        { id: '11', recordingName: 'Sunday Sermon', recordingDate: '01/07/2024', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/ysKV5G5a-uI?si=Vwe6713JJ0-SvFOY' },
        { id: '10', recordingName: 'Sunday Sermon', recordingDate: '12/31/2023', preacherName: 'Rob Robinson', filePath: 'https://youtu.be/IxV0ZspAlKY?si=APm0TqvA5t8HLeeh' },
        { id: '9', recordingName: 'Sunday Sermon - "The Samaritan Woamn, A Model Evangelist"', recordingDate: '12/24/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/x3P4295rlzA' },
        { id: '8', recordingName: 'Sunday Sermon - "When God Gives Up"', recordingDate: '12/17/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/z1yAMqbsOr0?si=CiSu4U490j1Odt7P' },
        { id: '7', recordingName: 'Sunday Sermon - "Love\'s Compositions"', recordingDate: '12/10/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/QHjXQDSbIO4?si=7iEgihBPWV40P8zJ' },
        { id: '6', recordingName: 'Sunday Sermon - "Love\'s Contrast"', recordingDate: '12/03/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/7wShF4ffzNw' },
        { id: '5', recordingName: 'Sunday Sermon - "Good Soldiers of Christ!"', recordingDate: '11/26/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/3cUgHKJy1fY' },
         { id: '4', recordingName: 'Sunday Sermon - "The Good Old Days!"', recordingDate: '11/19/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/zFkyWnmHVZM?si=g3RraryHD9O-mOK5' },
        { id: '3', recordingName: 'Sunday Sermon - "How Great is Your Faith?"', recordingDate: '11/12/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/ldgl5xYsnlw' },
        { id: '2', recordingName: 'Sunday Sermon - "Opportunities"', recordingDate: '11/05/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/DIbmGrpwOIc?si=tG1IRoLXU3xOqDDx' },
        { id: '1', recordingName: 'Sunday Sermon', recordingDate: '10/29/2023', preacherName: 'Tom Hodgins', filePath: 'https://youtu.be/Uf4Z3_GPwA0' }
    ];


      // Sort the array in descending order based on the id
    const sortedSermonRecordings = listOfSermonRecordings.slice().sort((a, b) => b.id - a.id);

    return ( 
         <section id="about">
            <div className="container-md">
                <div className="row align-content-stretch">
                    
                    {sortedSermonRecordings.map((sermon, index) => (
                            <ul className="col-md list-group ">
                            <li key={sermon.id} className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold text-align"><i>Sermon Name:</i> <strong>{sermon.recordingName}</strong></div>
                                    <div className="fw-bold text-align"><i>Sermon Date:</i> {sermon.recordingDate}</div>
                                    <div className="fw-bold text-align"><i>Preacher:</i> {sermon.preacherName}</div>
                                </div>
                                    {/* <ReactAudioPlayer
                                        src={sermon.filePath}
                                        autoPlay={false}
                                        controls
                                    /> */}
                                <ReactPlayer url={sermon.filePath} controls={true} />

                            </li>
                        </ul>
                        ))}
                    
                </div>
                <div className="container-md before-footer">&nbsp;</div>
            </div>
        </section>
     );
}
 
export default ArchivedSermons;