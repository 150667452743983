import InnerPageContent from "../../components/inner-page-content";
import "./about-us.css";


let content = {
  imageUrl: "./images/building-side-big.jpg",
  heading: "ABOUT US",
  text: <>
    <br/><br/>
    <p>
      We are absolutely commited to the ideal: <br /><br /> <span>"Speak where the Bible speaks. Be silent where it is silent!"</span> <br /><br />
      With the Word of God as our only guide, we seek to detemine what the original church was like, what it taught, what it practiced, and 
      to restore that church exactly. <i>(Cf. Galatians 1:8-9)</i>
    </p>
    <br />
    <h5>We are just Christians.</h5>
    <p>
      The confusion in the religious world today is far different from the unity shared by the early Christians. Loyalty to traditionalism and distinctive names divide
      men religiously. Surely such loyalties are contrary to scripture. We have no sectarian organization nor name, doctrine, or creed. Following the teachings of Christ
      and His apostles, we are Christians only.
    </p>
    <p>
      "Study to show thyself approved unto God, a workman that needeth not to be ashamed." <i>(2 Timothy 2:15)</i>
    </p>
    <br />
    <h5>We are A Concerned Church.</h5>
    <p>
      We are very concerned about others and ourselves; this is why we give our benevolent and mission programs top priority.
    </p>
    <p>
      "As we have therefore opportunity, let us do good unto all men, especially unto them who are of the household of faith." <i>(Galatians 6:10)</i>
    </p>
    <br />
    <h5>We are A Friendly Church.</h5>
    <p>
      We strive to open our hearts as well as our doors to all. Vistors are welcome as our honored guests.
    </p>
  </>
}


const AboutUs = () => {
    return ( 
       <InnerPageContent {...content} />
     );
}
 
export default AboutUs;