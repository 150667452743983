import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutUs from './pages/about-us';
import FindUs from './pages/find-us';
import Home from './pages/home';
import Navigation from './components/navigation';
import Footer from './components/footer';
import PageNotFound from './pages/page-not-found';
import WhatToExpect from './pages/what-to-expect';
import FreeOnlineBibleStudy from './pages/free-online-bible-study';
import RecentSermons from './pages/recent-sermons';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation />
          <div>
          <Routes>
              <Route path='/recent-sermons' element={ <RecentSermons />} />
              <Route path='/about-us' element={ <AboutUs />} />
              <Route path='/find-us' element={<FindUs />} />
              <Route path='/what-to-expect' element={<WhatToExpect />} />
              <Route path='/free-online-bible-study' element={<FreeOnlineBibleStudy />} />
              <Route path='/' element= { <Home />} />
              <Route path="*" element={< PageNotFound />} />
            </Routes>
        </div>  
        <Footer />
      </Router>
    </div>
  );
}

export default App;
