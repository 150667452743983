import { useState, useEffect } from "react";

const InnerPageContent = (props) => {
    const [img, setImg] = useState();

    useEffect(() => {
        if (props.imageUrl) {
        setImg(<img src={props.imageUrl} alt="Hartwell Church of Christ" className="w-100 img-thumbnail mt-4"  />)
    }
    },[])
    

    return ( 
        <section id="about">
            <div className="container">
                <div className="row align-content-stretch">
                 
                    <div className="col-md-4 col-lg-5">
                       {img}
                    </div>

                   
                    <div className="col-md-8 col-lg-7 text-justify">
                        <h3 className="section-title mb-3">{props.heading}</h3>
                        {props.text}
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default InnerPageContent;