import MapLink from "../../helpers/map-link";

const FooterMap = () => {

    return ( 
         <section className="has-bg-img-maps">
            <div className="container text-center">
                <h3 className="address">741 East Howell Street <br /> Hartwell, GA, 30643</h3>
            <a href={MapLink.googleMap} target="_blank" rel="noreferrer"><button className="btn btn-primary btn-lg mt-3">Find us</button></a>
            </div>
        </section>
     );
}
 
export default FooterMap;