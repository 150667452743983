import {Link} from 'react-router-dom';
import './more-information.css';

const MoreInformation = () => {
    return ( 
        <section id="MoreInformation">
        <div className="container">
           
            <div className="row">
                <div className="col-md-4">
                    <div className="card blog-post my-4 my-sm-5 my-md-0 card-height">
                        <img src='/images/bible-songBook-thumbnail.jpg' width={400} alt="#" />
                        <div className="card-body">
                            <h5 className="card-title">Sermon Recordings</h5>
                            
                                <div className="d-block mt-3"><Link to="/recent-sermons">Click here to watch</Link></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card blog-post my-4 my-sm-5 my-md-0 card-height">
                        <img src='/images/building-side-thumbnail.jpg' alt="#" />
                        <div className="card-body">
                            <h5 className="card-title">Where do we meet?</h5>
                            <p>741 East Howell Street, <br /> Hartwell, GA, 30643</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card blog-post my-4 my-sm-5 my-md-0 card-height">
                        <img src='/images/road-sign-thumbnail.jpg' alt="#" />
                        <div className="card-body">
                            <h5 className="card-title">When do we meet?</h5>
                                <p>Sunday <br /> Bible Study - 10 AM
                                    <br /> Worship - 11 AM
                                </p>
                                <p>Wednesday <br /> Bible Study - 7 PM
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
     );
}
 
export default MoreInformation;