import { useState, useEffect } from "react";
import HomePageHeader from '../../components/home-page-header';
import BoxContent from '../../components/box-content';
import WelcomeSection from '../../components/welcome';
import FooterMap from '../../components/footer-map';
import MoreInformation from '../../components/more-information';
import DisplayModal from "../../components/modal/display-modal";

import './home.css';


const Home = () => {
    const [modal, setModal] = useState(false);
    const Toggle = () => setModal(!modal);

    useEffect(() => {
        //call backend to check if there is promotion set
        let isPromotionSet = true;
        if (isPromotionSet) {
            //set other values to send to modal
            setModal(true);
        }
    },[])


    const filePath = () => {
        return "./images/AprGsplMtng.jpg";
    }

    const eventTitle = () => {
        return "Gospel Meeting - April 14-17, 2024";
    }
    
    return ( 
            <>
            {/*<DisplayModal title="Promotion Display Modal" show={modal} close={Toggle} filePath={filePath()} eventTitle={eventTitle()} /> */}
               <div className='topSpace'>         
                    <HomePageHeader />
                    <BoxContent />
                    <WelcomeSection />
                    <MoreInformation />
                    <FooterMap />
                </div>
            </>           
     );
}
 
export default Home;