import './contact-section.css';

const ContactSection = () => {
    return (  
            <section id="contact">
                <div className="container">
                    <div className="contact-card">
                        <div className="infos">
                            <h6 className="section-title mb-4">Find Us</h6>

                          <iframe title="google maps of Hartwell Church of Christ location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52703.31779499633!2d-82.9563691620392!3d34.35156322643882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8858808baaa21e91%3A0x9d19cc1e4d7e9e8!2sChurch%20of%20Christ!5e0!3m2!1sen!2sus!4v1672270644757!5m2!1sen!2sus" loading="lazy" width="250" height="150"></iframe>
                            
                            <div className="item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                                    </svg>
                                <div>                             
                                <h5>Location</h5>
                                    <p> 741 East Howell Street, <br /> Hartwell, GA, 30643</p>
                                </div>                          
                            </div>
                            <div className="item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                                </svg> &nbsp;
                                <div>
                                    <h5>Phone Number</h5>
                                    <p>(843) 304-5797</p>
                                </div>                          
                            </div>
                            <div className="item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                                    </svg> &nbsp;
                                <div className="mb-0">
                                    <h5>Email Address</h5>
                                    <p>hartcofc@gmail.com</p>
                                </div>
                            </div>
                            <div className="item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-browser-chrome" viewBox="0 0 16 16">
                                <path d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
                                </svg> &nbsp;
                                <div className="mb-0">
                                    <h5>hartwellcofc.org</h5>
                                    <p>hartcofc@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="form">
                            <h6 className="section-subtitle">We are working on fixing this form, please call us until its fixed</h6>
                            <h6 className="section-title mb-4">Get In Touch / Request Prayers</h6>
                            <form>
                                <div className="form-group">
                                    <input type="text" className="form-control form-control-lg" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control form-control-lg" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last Name" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control form-control-lg" id="exampleInputPassword1" placeholder="Phone Number" required />
                                </div>
                                <div className="form-group">
                                    <textarea name="contact-message" id="" cols="30" rows="7" className="form-control form-control-lg" placeholder="Message"></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary btn-block btn-lg mt-3">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            
    );
}
 
export default ContactSection;