import InnerPageContent from "../../components/inner-page-content";
import MapLink from "../../helpers/map-link";

const WhatToExpect = () => {
    let firstPara = {
    imageUrl: "./images/building-side-name-thumbnail.jpg",
    heading: "WE INVITE YOU TO BE OUR GUEST",
        text: 
            <>
                <br/><br/>
            <p>Here are some answers to questions people have about their first visit with us:</p>
             <h5>Where Do We Meet?</h5>
            <p>We worship at 741 East Howell St, Hartwell, GA, 30643.  <br />
                You can find us using a Google Map with our location  <a href={MapLink.googleMap} target="_blank" rel="noreferrer">HERE</a>
            </p>
            <p>
                We have limited parking at the side of the building and on the street side.
            </p>
            <p>
                You can enter from the double glass doors in the front of the building. Our restrooms are on the right-side of the foyer as soon as you enter the building.
            </p>
            <p>&nbsp;</p>

            <h5>When Do We Meet?</h5>
            <p>Sunday Bible classes begin at 10:00 AM and Worship begins at 11:00. We also meet
on Wednesdays at 7:00 PM for Bible Study.</p>
            <p>&nbsp;</p>

            <h5>What Should I wear?</h5>
            <p>There is no rigid dress code. Some men will be wearing coats and ties, and women
wear dresses or slacks. Some wear business casual, others wear jeans.
            </p>
            <p>&nbsp;</p>

            <h5>What Should I Expect?</h5>
            <p>
                <strong><u>Singing</u></strong> <br />
                At every service we sing together as a congregation a-Capella. Song books are located
in slots behind the pews.
            </p>
            <p>
                <u>Prayer</u> <br />
                We believe in prayers. Through the service, different men who are the members of the church lead prayers. 
            </p>
            <p>
                <u>Communion</u> <br />
                Partaking of the Lord&#39;s Supper each Sunday is one of the most important parts of the
worship. Due to COVID-19, communion is provided in individual sealed serving
containers.
            </p>
            <p>
                <u>Offering</u> <br />
               We set aside portions from our earnings each week and provide an opportunity for our
members to donate money to the Lord’s work. While you are welcome to give if you
desire, guests are not expected to do so.
            </p>
            <p>
                <u>Teaching</u> <br />
               Preaching and Teaching of God&#39;s Word is an important part of our gatherings.
Valuable, Bible-based lessons are important for a “successful and happy life.”
            </p>
            <p>
                <u>Invitation</u> <br />
                At the end of the service, we stand and sing a song of Invitation. This is the
opportunity for anyone in need to respond publicly by letting their need be known.
                <br /><br/>
                One may desire prayers on their behalf or has decided to <b>"Repent, and be baptized
                    in the name of Jesus Christ for the forgiveness of their sins" Acts 2:38</b>
                <br /><br/>
                Or if you want someone to pray with you and don&#39;t want it to be so publicly known,
you can tell one of the men privately.
            </p>
            <p>
                <u>After the Worship Service</u> <br />
               People will gather to talk and laugh about life and catch up on each other's news.
They will want to meet and get to know you. So please stay around long enough so we
have a chance to greet you before you leave.
                <br /> <br/>
              Every second Sunday of the month we eat lunch together as a congregation. Members
cook their favorite dishes and like to share them. We would love to have you join us
for lunch on those occasions. 
            </p>
        </>
    }

    return ( 
        <>
            <InnerPageContent {...firstPara} />
        </>       
     );
}
 
export default WhatToExpect;