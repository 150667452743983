const WelcomeSection = () => {
    return ( 
        <section id="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-5 col-lg-4">
                        <img src='/images/preacher.jpg' alt="Hartwell Church of Christ" className="w-100 img-thumbnail mb-3" />
                    </div>
                    <div className="col-md-7 col-lg-8 text-justify">
                        <h6 className="section-title mb-3">YOU ARE A FAMILY. </h6>
                        <p> Welcome to the <i>Hartwell congregation of the Churches of Christ</i>. It is our intent, our desire, and our
                            duty, to study, to practice, and to share the Word and Will of God strictly and accurately. We appreciate
                            your attendance and fellowship and hope that we will be much of an encouragement to you as you are to us. 
                            May God grant each of us the wisdom, strength, and detemination we need in order to run the good race, to fight the good fight, and to live our lives in accordance with HIS WILL, on HIS TERMS!!
                        </p>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default WelcomeSection;